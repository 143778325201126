import React from "react";
import ReactDOM from "react-dom";

import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./modules";
//debug
import { composeWithDevTools } from "redux-devtools-extension";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import firebaseConfig from "./config/firebaseConfig";
import "./css/core.css";
import Root from "./Root.jsx";

const store = createStore(rootReducer, composeWithDevTools());
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Root />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
