import React from "react";
import "./textArea.css";

const TextArea = ({ placeholder, inputTitle, handler, defaultValue, target }) => {
  return (
    <div className="textArea">
      <div className="inputTitle"> - {inputTitle}</div>
      <textarea
        type="text"
        className="input"
        placeholder={placeholder}
        onChange={(e) => handler(e, target)}
        defaultValue={defaultValue? defaultValue: ""}

      />
    </div>
  );
};

export default TextArea;
