import React, { useEffect } from "react";
import ClipLoader from "react-spinners/ScaleLoader";
import "./fullLoading.css";
const FullLoading = ({ msg, display }) => {

	useEffect(()=>{
		console.log(msg);
	}, [msg])

  return (
    <div
      className="nowLoading"
      style={{
        display: display ? "flex" : "none",
      }}
    >
      <ClipLoader
        color="#1a4a9d"
        width={10}
        height={100}
        radius={50}
        margin={20}
        loading={true}
      />
      <span className="status">{msg}</span>
    </div>
  );
};

export default FullLoading;
