import {useRef, useCallback, useState, useEffect} from "react";
import firebase from "firebase/app";
import markdownToTxt from "markdown-to-txt";

import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";

import { images2url, uploadDocument } from "../lib/api";
import TextInput from "../components/TextInput";
import DateInput from "../components/DateInput";
import FileInput from "../components/FileInput";
import ToggleButton from "../components/ToggleButton";
import FullLoading from "../components/FullLoading";

import "./writeDocument.css";
import useDocument from "../custom/useDocument";

const requireItem = ["is_result", "start_date", "end_date", "title", "mobile_image", "main_image", "thumb_image"]

const EventWrite = ({ history }) => {
  const editor = useRef();

  // eslint-disable-next-line no-unused-vars
  const [document, setDocument, handleDocument, handleNoneEvent] = useDocument({
    is_result: false,
    start_date: "",
    end_date: "",
    title: "",
    mobile_image: "",
    main_image: "",
    thumb_image: "",
    article_md: "",
    article_str: "",
  });

  const [isUpload, setUpload] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(()=>{
    console.log(document)
  },[document])

  const handleSave = useCallback(async () => {
    for(const idx in requireItem){
      const key = requireItem[idx];

      if (document[key] === null || document[key].toString() === ""){
        console.log(key)
        alert("일부 정보가 누락되었습니다");
        return;
      }
    }

    try {
      setUpload(true);
      setMessage("에디터 이미지 업로드 중");
      let md = editor.current.getInstance().getMarkdown();
      md = await images2url(md);

      const saveDocument = {
        ...document,
        start_date: firebase.firestore.Timestamp.fromDate(new Date(document.start_date)),
        end_date: firebase.firestore.Timestamp.fromDate(new Date(document.end_date)),
        article_md: md,
        article_str: markdownToTxt(md.replace(/<[^>]*>?/gm, '')).replace(/image/g, ""),
      }

      setMessage("전체 문서 업로드 중");
      const result = await uploadDocument(saveDocument, "event");

      setMessage("업로드 완료! (3초후 페이지 이동)");
      
      setTimeout(() => {
        history.push("/event/detail?id=" + result.id);
      }, 3000);
    } catch (err) {
      console.log(err)
      alert("업로드 중 얘기치 않은 오류가 발생했습니다");
    }
  }, [document, history]);

  return (
    <div className="writeDocument">
      <FullLoading display={isUpload} msg={message} />
      <h1>새 이벤트 작성</h1>
      <TextInput
        inputTitle="이벤트 제목"
        placeholder="이벤트에 들어가는 제목을 입력해주세요"
        handler={handleDocument}
        defaultValue={document.title}
        target="title"
      />

      <DateInput
        inputTitle="이벤트 시작일"
        handler={handleDocument}
        defaultValue={document.start_date}
        target="start_date"
      />

      <DateInput
        inputTitle="이벤트 종료일"
        handler={handleDocument}
        defaultValue={document.end_date}
        target="end_date"
      />

      <FileInput
        inputTitle="썸네일 이미지"
        handler={handleNoneEvent}
        width={360}
        height={179}
        information="가로 360px 세로 180px 권장"
        defaultValue={document.thumb_image}
        target="thumb_image"
      />

      <FileInput
        inputTitle="본문 이미지 (PC)"
        handler={handleNoneEvent}
        information="가로 993px 세로 660px 이상 권장"
        defaultValue={document.main_image}
        target="main_image"
      />

      <FileInput
        inputTitle="본문 이미지 (모바일)"
        handler={handleNoneEvent}
        information="가로 993px 세로 660px 이상 권장"
        defaultValue={document.mobile_image}
        target="mobile_image"
      />

      <ToggleButton
        inputTitle="이벤트 결과 페이지"
        handler={handleNoneEvent}
        value={document.is_result}
        target="is_result"
      />

      <Editor
        previewStyle="vertical"
        width="100%"
        height="90vh"
        initialEditType="wysiwyg"
        placeholder="글쓰기"
        ref={editor}
      />

      <button onClick={handleSave} className="upload">
        업로드
      </button>
    </div>
  );
};

export default EventWrite;
