import React, { useState, useEffect, useRef } from "react";
import queryString from "query-string";

import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/react-editor";

import RemoveButton from "../components/RemoveButton";
import { getSingleDocument } from "../lib/api";
import "./serviceDetail.css";
import EditButton from "../components/EditButton";

const EventDetail = ({ location, history }) => {
  const viewer = useRef();
  const [docID, setDocID] = useState("");
  const [document, setDocument] = useState({
    is_result: false,
    start_date: "",
    end_date: "",
    title: "",
    mobile_image: "",
    main_image: "",
    thumb_image: "",
    article_md: "",
    article_str: "",
  });
  useEffect(() => {
    const query = queryString.parse(location.search);
    setDocID(query.id);
  }, [location]);

  useEffect(() => {
    if (docID === "") return;
    getSingleDocument("event", docID)
      .then((nextDocument) => {
        setDocument(nextDocument);
      });
  }, [docID]);

  useEffect(() => {
    viewer.current.getInstance().setMarkdown(document.article_md);
  }, [document]);

  return (
    <div className="serviceDetail">
      <div className="head">
        <div className="title">{document.title}</div>
      </div>
      <div className="date">
        <p>이벤트 기간</p>
        <div className="start">
          {document.start_date &&
            document.start_date.toDate().toLocaleDateString()}
        </div>{" "}
        ~{" "}
        <div className="end">
          {document.start_date &&
            document.end_date.toDate().toLocaleDateString()}
        </div>
      </div>
      <img src={document.main_image} className="titleImage event" alt="" />
      <Viewer previewStyle="vertical" width="100%" ref={viewer} />
      <div className="editButtons">
        <RemoveButton target="event" id={docID} history={history} />
        <EditButton target="event" id={docID} />
      </div>
    </div>
  );
};

export default EventDetail;
