import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { getSingleDocument } from "../lib/api";

import ImageContainer from "../components/ImageContainer";
import RemoveButton from "../components/RemoveButton";

import "./serviceDetail.css";
import EditButton from "../components/EditButton";
const ToonDetail = ({ location, history }) => {
  const [docID, setDocID] = useState("");
  const [document, setDocument] = useState({
    article: "",
    contents: [],
    tag: [],
    title: "",
    writer: "",
  });
  useEffect(() => {
    const query = queryString.parse(location.search);
    setDocID(query.id);
  }, [location]);

  useEffect(() => {
    if (docID === "") return;
    getSingleDocument("toon", docID)
      .then(nextDocument => {
        setDocument(nextDocument);
      })

  }, [docID]);

  return (
    <div className="serviceDetail">
      <div className="head">
        <div className="title">{document.title}</div>
      </div>
      <div className="profile">
        <img src="/assets/profile-icon.jpg" alt="asfasdf" />
        <p>{document.writer}</p>
      </div>
			<ImageContainer contents={document.contents}/>
      <div className="toonArticle">
        <div className="article">{document.article}</div>
        <div className="tags">
          {document.tag.map((val, idx) => (
            <span key={idx}>{`#${val}`}</span>
          ))}
        </div>
      </div>
      <div className="editButtons">
        <RemoveButton target="toon" id={docID} history={history} />
        <EditButton target="toon" id={docID} />
      </div>
    </div>
  );
}

export default ToonDetail
