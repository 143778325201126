import {useRef, useCallback, useState, useEffect} from "react";
import firebase from "firebase/app";
import markdownToTxt from "markdown-to-txt";

import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";

import {getSingleDocument, images2url, updateDocument } from "../lib/api";
import queryString from "query-string";

import SelectInput from "../components/SelectInput";
import TextInput from "../components/TextInput";
import DateInput from "../components/DateInput";
import FileInput from "../components/FileInput";
import ToggleButton from "../components/ToggleButton";
import FullLoading from "../components/FullLoading";

import "./writeDocument.css";
import useDocument from "../custom/useDocument";

const noneImageUrl = "https://firebasestorage.googleapis.com/v0/b/dearcare-test.appspot.com/o/images%2Fnone.jpeg?alt=media&token=516a7039-40a5-4666-b09c-29d2defc8c39"

const editionCategory = {
  _교육: {
    name: "교육",
  },
  _제휴: {
    name: "제휴",
  },
  _이벤트: {
    name: "이벤트",
  },
  _기타: {
    name: "기타",
  },
};

const requireItem = ["is_notice", "created_date", "title", "category"]

const EditionEdit = ({ history, location }) => {
  const editor = useRef();
  const [docID, setDocID] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [document, setDocument, handleDocument, handleNoneEvent] = useDocument({
    is_notice: false,
    created_date: "",
    title: "",
    thumb_image: "",
    article_md: "",
    article_str: "",
    category: {
      root: "not",
    },
  });

  const [isUpload, setUpload] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(()=>{
    console.log(document)
  },[document])

  useEffect(() => {
    const query = queryString.parse(location.search);
    setDocID(query.id);
  }, [location]);

  useEffect(() => {
    if (docID === "") return;

    getSingleDocument("edition", docID)
      .then((nextDocument) => {
        editor.current.getInstance().setMarkdown(nextDocument.article_md);
        console.log(nextDocument);
        setDocument({
          ...nextDocument,
          created_date: nextDocument.created_date.toDate().toISOString().split("T")[0],
        });
      });
  }, [docID, setDocument]);

  const handleSave = useCallback(async () => {
    for(const idx in requireItem){
      const key = requireItem[idx];

      if (document[key] === null || document[key].toString() === ""){
        console.log(key)
        alert("일부 정보가 누락되었습니다");
        return;
      }
    }

    if (document.category.root === "not" ){
      alert("일부 정보가 누락되었습니다");
      return;
    }

    try {
      setUpload(true);
      setMessage("에디터 이미지 업로드 중");
      let md = editor.current.getInstance().getMarkdown();
      md = await images2url(md);

      const saveDocument = {
        ...document,
        thumb_image: document.is_notice? document.thumb_image: noneImageUrl, 
        created_date: firebase.firestore.Timestamp.fromDate(new Date(document.created_date)),
        article_md: md,
        article_str: markdownToTxt(md.replace(/<[^>]*>?/gm, '')).replace(/image/g, ""),
        root_category: document.category.root,
      }

      setMessage("전체 문서 업로드 중");
      await updateDocument(saveDocument, docID, "edition");

      setMessage("업로드 완료! (3초후 페이지 이동)");
      
      setTimeout(() => {
        history.push("/edition/detail?id=" + docID);
      }, 3000);
    } catch (err) {
      console.log(err)
      alert("업로드 중 얘기치 않은 오류가 발생했습니다");
    }
  }, [document, history, docID]);

  return (
    <div className="writeDocument">
      <FullLoading display={isUpload} msg={message} />
      <h1>공지사항 수정</h1>
      <TextInput
        inputTitle="공지사항 제목"
        placeholder="공지사항에 들어가는 제목을 입력해주세요"
        handler={handleDocument}
        defaultValue={document.title}
        target="title"
      />

      <DateInput
        inputTitle="공지사항 작성일"
        handler={handleDocument}
        defaultValue={document.created_date}
        target="created_date"
      />

      <SelectInput
        inputTitle="카테고리"
        category={editionCategory}
        handler={handleNoneEvent}
        exist_child={false}
        value={document.category}
        target="category"
      />

      <FileInput
        inputTitle="썸네일 이미지"
        handler={handleNoneEvent}
        width={360}
        height={179}
        information="가로 360px 세로 180px 권장"
        defaultValue={document.thumb_image}
        target="thumb_image"
      />

      <ToggleButton
        inputTitle="상단노출 설정"
        handler={handleNoneEvent}
        value={document.is_notice}
        target="is_notice"
      />

      <Editor
        previewStyle="vertical"
        width="100%"
        height="90vh"
        initialEditType="wysiwyg"
        placeholder="글쓰기"
        ref={editor}
      />

      <button onClick={handleSave} className="upload">
        업로드
      </button>
    </div>
  );
};

export default EditionEdit;
