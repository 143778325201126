import React, { useState, useEffect, useRef } from "react";
import queryString from "query-string";
import { getSingleDocument } from "../lib/api";

import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/react-editor";

import RemoveButton from "../components/RemoveButton";
import "./serviceDetail.css";
import EditButton from "../components/EditButton";

function ReviewDetail({ location, history }) {
  const viewer = useRef();
  const [docID, setDocID] = useState("");
  const [document, setDocument] = useState({
    title: "",
    writer: "",
    created_date: "",
    star: 10,
    main_image: "",
    thumb_image: "",
    article_md: "",
    article_str: "",
  });
  useEffect(() => {
    const query = queryString.parse(location.search);
    setDocID(query.id);
  }, [location]);

  useEffect(() => {
    if (docID === "") return;
    getSingleDocument("review", docID)
      .then((nextDocument)=>{
        setDocument(nextDocument);
      });
    // console.log(nextDocument);

  }, [docID]);

  useEffect(() => {
    viewer.current.getInstance().setMarkdown(document.article_md);
  }, [document]);

  return (
    <div className="serviceDetail">
      <div className="head">
        <div className="title">{document.title}</div>
        <div className="stars">
          {[...Array(5).keys()].map((_, idx) => {
            const star = document.star - 1;
            if (idx * 2 > star)
              return ""
            else if ((star + 1) % 2 === 1 && (idx * 2 >= star)) 
              return <div key={idx} className="half"/>
            else                  
              return <div key={idx} />
          })}
        </div>
      </div>
      <div className="profile">
        <img src="/assets/profile-icon.jpg" alt="asfasdf" />
        <p>{document.writer}</p>
      </div>
      <Viewer previewStyle="vertical" width="100%" ref={viewer} />
      <div className="editButtons">
        <RemoveButton target="review" id={docID} history={history} />
        <EditButton target="review" id={docID} />
      </div>
    </div>
  );
}

export default ReviewDetail;
