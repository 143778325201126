import React from "react";
import { Link } from "react-router-dom"


import "./removeButton.css";

const EditButton = ({ target, id}) => {
  return (
    <Link to={`/${target}/edit?id=${id}`} className="removeButton" >
      게시글 수정
    </Link>
  );
};

export default EditButton;
