const servicePriceDict = {
  single :{
    normal: {
      one: {
        text: "A-가-①형",
        short: {
          days : 5,
          price: 592,
          support: 521,
        },
        middle: {
          days : 10,
          price: 1184,
          support: 894,
        },
        expand: {
          days : 15,
          price: 1776,
          support: 1211,
        }
      },
      two: {
        text: "A-가-②형",
        short: {
          days : 10,
          price: 1184,
          support: 1069,
        },
        middle: {
          days : 15,
          price: 1776,
          support: 1376,
        },
        expand: {
          days : 20,
          price: 2368,
          support: 1656,
        }
      },
      three: {
        text: "A-가-③형",
        short: {
          days : 10,
          price: 1184,
          support: 1110,
        },
        middle: {
          days : 15,
          price: 1776,
          support: 1428,
        },
        expand: {
          days : 20,
          price: 2368,
          support: 1718,
        }
      }
    },
    under: {
      one: {
        text: "A-통합-①형",
        short: {
          days : 5,
          price: 592,
          support: 460,
        },
        middle: {
          days : 10,
          price: 1184,
          support: 790,
        },
        expand: {
          days : 15,
          price: 1776,
          support: 1070,
        }
      },
      two: {
        text: "A-통합-②형",
        short: {
          days : 10,
          price: 1184,
          support: 944,
        },
        middle: {
          days : 15,
          price: 1776,
          support: 1215,
        },
        expand: {
          days : 20,
          price: 2368,
          support: 1463,
        }
      },
      three: {
        text: "A-통합-③형",
        short: {
          days : 10,
          price: 1184,
          support: 979,
        },
        middle: {
          days : 15,
          price: 1776,
          support: 1261,
        },
        expand: {
          days : 20,
          price: 2368,
          support: 1518,
        }
      }
    },
    over: {
      one: {
        text: "A-라-①형",
        short: {
          days : 5,
          price: 592,
          support: 368,
        },
        middle: {
          days : 10,
          price: 1184,
          support: 633,
        },
        expand: {
          days : 15,
          price: 1776,
          support: 858,
        }
      },
      two: {
        text: "A-라-②형",
        short: {
          days : 10,
          price: 1184,
          support: 756,
        },
        middle: {
          days : 15,
          price: 1776,
          support: 974,
        },
        expand: {
          days : 20,
          price: 2368,
          support: 1173,
        }
      },
      three: {
        text: "A-라-③형",
        short: {
          days : 10,
          price: 1184,
          support: 784,
        },
        middle: {
          days : 15,
          price: 1776,
          support: 1010,
        },
        expand: {
          days : 20,
          price: 2368,
          support: 1217,
        }
      }
    }
  },
  twin :{
    normal: {
      one: {
        text: "B-가-①형",
        short: {
          days : 10,
          price: 1520,
          support: 1477,
        },
        middle: {
          days : 15,
          price: 2280,
          support: 1899,
        },
        expand: {
          days : 20,
          price: 3040,
          support: 2284,
        }
      },
      two: {
        text: "B-가-②형",
        short: {
          days : 10,
          price: 2072,
          support: 2026,
        },
        middle: {
          days : 15,
          price: 3108,
          support: 2701,
        },
        expand: {
          days : 20,
          price: 4144,
          support: 3337,
        }
      },
      three: {
        text: "B-가-②형",
        short: {
          days : 10,
          price: 2072,
          support: 2026,
        },
        middle: {
          days : 15,
          price: 3108,
          support: 2701,
        },
        expand: {
          days : 20,
          price: 4144,
          support: 3337,
        }
      },
    },
    under: {
      one: {
        text: "B-통합-①형",
        short: {
          days : 10,
          price: 1520,
          support: 1303,
        },
        middle: {
          days : 15,
          price: 2280,
          support: 1676,
        },
        expand: {
          days : 20,
          price: 3040,
          support: 2017,
        }
      },
      two: {
        text: "B-통합-②형",
        short: {
          days : 10,
          price: 2072,
          support: 1840,
        },
        middle: {
          days : 15,
          price: 3108,
          support: 2463,
        },
        expand: {
          days : 20,
          price: 4144,
          support: 3051,
        }
      },
      three: {
        text: "B-통합-②형",
        short: {
          days : 10,
          price: 2072,
          support: 1840,
        },
        middle: {
          days : 15,
          price: 3108,
          support: 2463,
        },
        expand: {
          days : 20,
          price: 4144,
          support: 3051,
        }
      },
    },
    over: {
      one: {
        text: "B-라-①형",
        short: {
          days : 10,
          price: 1520,
          support: 1042,
        },
        middle: {
          days : 15,
          price: 2280,
          support: 1341,
        },
        expand: {
          days : 20,
          price: 3040,
          support: 1615,
        }
      },
      two: {
        text: "B-라-②형",
        short: {
          days : 10,
          price: 2072,
          support: 1561,
        },
        middle: {
          days : 15,
          price: 3108,
          support: 2106,
        },
        expand: {
          days : 20,
          price: 4144,
          support: 2622,
        }
      },
      three: {
        text: "B-라-②형",
        short: {
          days : 10,
          price: 2072,
          support: 1561,
        },
        middle: {
          days : 15,
          price: 3108,
          support: 2106,
        },
        expand: {
          days : 20,
          price: 4144,
          support: 2622,
        }
      },
    }
  },
  over: {
    normal: {
      one: {
        text: "C-가형",
        short: {
          days : 15,
          price: 3552,
          support: 3477,
        },
        middle: {
          days : 20,
          price: 4736,
          support: 4151,
        },
        expand: {
          days : 25,
          price: 5920,
          support: 4832,
        }
      },
      two: {
        text: "C-가형",
        short: {
          days : 15,
          price: 3552,
          support: 3477,
        },
        middle: {
          days : 20,
          price: 4736,
          support: 4151,
        },
        expand: {
          days : 25,
          price: 5920,
          support: 4832,
        }
      },
      three: {
        text: "C-가형",
        short: {
          days : 15,
          price: 3552,
          support: 3477,
        },
        middle: {
          days : 20,
          price: 4736,
          support: 4151,
        },
        expand: {
          days : 25,
          price: 5920,
          support: 4832,
        }
      },
    },
    under: {
      one: {
        text: "C-통합형",
        short: {
          days : 15,
          price: 3552,
          support: 3177,
        },
        middle: {
          days : 20,
          price: 4736,
          support: 3809,
        },
        expand: {
          days : 25,
          price: 5920,
          support: 4447,
        }
      },
      two: {
        text: "C-통합형",
        short: {
          days : 15,
          price: 3552,
          support: 3177,
        },
        middle: {
          days : 20,
          price: 4736,
          support: 3809,
        },
        expand: {
          days : 25,
          price: 5920,
          support: 4447,
        }
      },
      three: {
        text: "C-통합형",
        short: {
          days : 15,
          price: 3552,
          support: 3177,
        },
        middle: {
          days : 20,
          price: 4736,
          support: 3809,
        },
        expand: {
          days : 25,
          price: 5920,
          support: 4447,
        }
      },
    },
    over: {
      one: {
        text: "C-라형",
        short: {
          days : 15,
          price: 3552,
          support: 2726,
        },
        middle: {
          days : 20,
          price: 4736,
          support: 3295,
        },
        expand: {
          days : 25,
          price: 5920,
          support: 3868,
        }
      },
      two: {
        text: "C-라형",
        short: {
          days : 15,
          price: 3552,
          support: 3177,
        },
        middle: {
          days : 20,
          price: 4736,
          support: 3809,
        },
        expand: {
          days : 25,
          price: 5920,
          support: 4447,
        }
      },
      three: {
        text: "C-라형",
        short: {
          days : 15,
          price: 3552,
          support: 3177,
        },
        middle: {
          days : 20,
          price: 4736,
          support: 3809,
        },
        expand: {
          days : 25,
          price: 5920,
          support: 4447,
        }
      },
    }
  }
}

export default servicePriceDict;