import {useCallback, useEffect, useState} from "react";
import { uploadDocument, splitComma } from "../lib/api";

import SelectInput from "../components/SelectInput";
import TextInput from "../components/TextInput";
import FullLoading from "../components/FullLoading";
import TextArea from "../components/TextArea";
import FilePicker from "../components/FilePicker";
import "./writeDocument.css";
import useDocument from "../custom/useDocument";

const toonCategory = {
  _4컷: {
    name: "4컷",
  },
  _단편: {
    name: "단편",
  },
  _시리즈: {
    name: "시리즈",
  },
};

const requireItem = ["article", "contents", "tag", "title", "writer", "category"]

const ToonWrite = ({history}) => {
  const [document, setDocument, handleDocument, handleNoneEvent] = useDocument({
    article: "",
    contents: [],
    tag: "",
    title: "",
    writer: "",
    category: {
      root: "not",
    },
  });

  const [isUpload, setUpload] = useState(false);
  const [message, setMessage] = useState("");


  useEffect(()=>{
    console.log(document)
  },[document])

  const handleContents = useCallback(
    (url, idx) => {
      const newContent = document.contents.slice();
      if (idx >= newContent.length) {
        newContent.push(url);
      } else {
        newContent[idx] = url;
      }
      console.log(newContent);
      setDocument({
        ...document,
        contents: newContent
      });
    },
    [document, setDocument]
  );

  const handleSave = useCallback(async () => {
    for(const idx in requireItem){
      const key = requireItem[idx];

      if (document[key] === null || document[key].toString() === ""){
        alert("일부 정보가 누락되었습니다");
        return;
      }
    }

    if (document.category.root === "not" ){
      alert("일부 정보가 누락되었습니다");
      return;
    }

    try {
      setUpload(true);

      const saveDocument = {
        ...document,
        tag: splitComma(document.tag),
        root_category: document.category.root,
      }

      setMessage("전체 문서 업로드 중");
      const result = await uploadDocument(saveDocument, "toon");
      console.log(result);
      setMessage("업로드 완료! (3초후 페이지 이동)");

      setTimeout(() => {
        history.push("/toon/detail?id=" + result.id);
      }, 3000);
    } catch (err) {
      console.log(err);
      alert("업로드 중 얘기치 않은 오류가 발생했습니다");
    }

  }, [document, history]);

	return (
    <div className="writeDocument">
      <FullLoading display={isUpload} msg={message} />
      <h1>새 디어툰 작성</h1>
      <TextInput
        inputTitle="디어툰 제목"
        placeholder="디어툰에 들어가는 제목을 입력해주세요"
        handler={handleDocument}
        defaultValue={document.title}
        target="title"
      />
      <TextInput
        inputTitle="작성자"
        placeholder="작성자명을 입력해주세요"
        handler={handleDocument}
        defaultValue={document.writer}
        target="writer"
      />
      <SelectInput
        inputTitle="카테고리"
        category={toonCategory}
        handler={handleNoneEvent}
        exist_child={false}
        value={document.category}
        target="category"
      />
      <div className="fileInput content">
        <div className="inputTitle"> - 컨텐츠 이미지</div>
        <div className="contentContainer">
          {[...Array(document.contents.length + 1).keys()].map((val, idx) => (
            <FilePicker
              key={idx}
              width={250}
              height={250}
              information="600px * 600px 이상 정사각형 권장"
              handler={(url, target) => {
                handleContents(url, idx);
              }}
              defaultValue={document.contents[idx]}
            />
          ))}
        </div>
      </div>

      <TextArea
        inputTitle="내용"
        handler={handleDocument}
        defaultValue={document.article}
        target="article"
        placeholder="내용을 입력해주세요"
      />
      <TextInput
        inputTitle="태그"
        placeholder=", 로 태그를 분리시켜 주세요 (예: 태그1, 태그2, 태그3)"
        handler={handleDocument}
        defaultValue={document.tag}
        target="tag"
      />

      <button onClick={handleSave} className="upload">
        업로드
      </button>
    </div>
  );
}

export default ToonWrite
