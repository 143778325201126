import {useCallback, useState} from "react";

const useDocument = (initDocument = null) => {
  const [document, setDocument] = useState(initDocument)

  const handleDocument = useCallback((e, target) => {
    setDocument({
      ...document,
      [target]: e.target.value
    });
  }, [document])

  const handleNoneEvent = useCallback((value, target) => {
    setDocument({
      ...document,
      [target]: value
    });
  }, [document]);

  return [document, setDocument, handleDocument, handleNoneEvent];
}

export default useDocument;