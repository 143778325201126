import React, { useState, useCallback } from "react";
import "./imageContainer.css";

const WIDTH_SIZE = 600;

const ImageContainer = ({ contents }) => {
  const [page, setPage] = useState(0);

  const handleLeft = useCallback(() => {
    if (page <= 0) return;
    setPage(page - 1);
  }, [page]);

  const handleRight = useCallback(() => {
    if (page >= contents.length - 1) return;
    setPage(page + 1);
  }, [page, contents]);
  
  const handlePicker = (nextPage) => {
    setPage(nextPage);
  };

  return (
    <div className="imageContainer">
      <div className="mainBox">
        <button
          className="left"
          onClick={handleLeft}
          style={{
            opacity: page === 0 ? 0 : 1,
          }}
        >
          <img src="/assets/arrow-left.png" alt="left" />
        </button>
        <div className="contents">
          <div
            style={{
              transform: `translateX(-${WIDTH_SIZE * page}px)`,
            }}
            className="inner"
          >
            {contents.map((val, idx) => (
              <img src={val} alt="" key={idx} />
            ))}
          </div>
        </div>
        <button
          className="right"
          onClick={handleRight}
          style={{
            opacity: page === contents.length - 1 ? 0 : 1,
          }}
        >
          <img src="/assets/arrow-right.png" alt="right" />
        </button>
      </div>
      <div className="picker">
        {contents.map((_, idx) => (
          <span
            key={idx}
            className={idx === page ? "selected" : ""}
            onClick={() => {
              handlePicker(idx);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageContainer;
