import React from "react";
import { Link } from "react-router-dom";
import "./toon.css";
import useDocumentList from "../custom/useDocumentList";

const Toon = () => {
  const [article] = useDocumentList("toon", "timestamp")
  return (
    <div className="toon">
      <h1>전체 디어툰 리스트</h1>
      <div className="toonContainer">
        <Link className="createToon" to="/toon/write">
          새 디어툰 작성
        </Link>
        {article.map((val, idx) => (
          <Link to={"/toon/detail?id=" + val.id} className="item" key={val.id}>
            <div className="left">
              <div className="title">{val.title}</div>
              <div className="content">
                {val.article}
                <div className="tags">
                  {val.tag.map((val, idx) => (
                    <span key={idx}>{`#${val} `}</span>
                  ))}
                </div>
              </div>
            </div>
            <div className="right">
              <img src={val.contents[0]} alt="toon" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Toon;
