import {useRef, useCallback, useState, useEffect} from "react";
import firebase from "firebase/app";
import markdownToTxt from "markdown-to-txt";
import { images2url, uploadDocument } from "../lib/api";
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import TextInput from "../components/TextInput";
import DateInput from "../components/DateInput";
import FileInput from "../components/FileInput";
import ToggleButton from "../components/ToggleButton";
import FullLoading from "../components/FullLoading";
import "./writeDocument.css";
import useDocument from "../custom/useDocument";

const requireItem = ["is_best", "writer", "created_date", "title", "thumb_image", "star", "company"]

export default function ReviewWrite({ history }) {

  const StarPicker = () => {
    return (
      <div className="starPicker">
        <div className="inputTitle"> - 별점</div>
        <div className="starContainer">
          {[...Array(5).keys()].map((_, idx) => {
            return( 
            <div key={idx} className={(idx * 2 > document.star ? "grey " : (document.star + 1) % 2 === 1 && (idx * 2 >= document.star)? "half ": "") + "star"}>
              <div className="left" onClick={() => handleNoneEvent(idx * 2, "star")} />
              <div className="right" onClick={() => handleNoneEvent(idx * 2 + 1, "star")}/>
            </div>
          )})}
        </div>
      </div>
    );
  };
  const editor = useRef();
  
  // eslint-disable-next-line
  const [document, setDocument, handleDocument, handleNoneEvent] = useDocument({
    is_best: false,
    writer: "",
    created_date: "",
    title: "",
    thumb_image: "",
    article_md: "",
    article_str: "",
    star: 9,
    company: "디어케어-대구점", //후에 지점이 늘어날시에 이와 관련된 처리를 할 수 있게 하기
  });


  const [isUpload, setUpload] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(()=>{
    console.log(document)
  }, [document])

  const handleSave = useCallback(async () => {
    for(const idx in requireItem){
      const key = requireItem[idx];

      if (document[key] === null || document[key].toString() === ""){
        alert("일부 정보가 누락되었습니다");
        return;
      }
    }

    try {
      setUpload(true);
      setMessage("에디터 이미지 업로드 중");
      let md = editor.current.getInstance().getMarkdown();
      md = await images2url(md);

      const saveDocument = {
        ...document,
        star: document.star + 1,
        created_date: firebase.firestore.Timestamp.fromDate(new Date(document.created_date)),
        article_md: md,
        article_str: markdownToTxt(md.replace(/<[^>]*>?/gm, '')).replace(/image/g, ""),
      }

      setMessage("전체 문서 업로드 중");
      const result = await uploadDocument(saveDocument, "review");

      setMessage("업로드 완료! (3초후 페이지 이동)");

      setTimeout(() => {
        history.push("/review/detail?id=" + result.id);
      }, 3000);
    } catch (err) {
      console.log(err);
      alert("업로드 중 얘기치 않은 오류가 발생했습니다");
    }

    // setTimeout(() => )
  }, [document, history]);

  return (
    <div className="writeDocument">
      <FullLoading display={isUpload} msg={message} />
      <h1>새 후기 작성</h1>
      <TextInput
        inputTitle="후기 제목"
        placeholder="후기에 들어가는 제목을 입력해주세요"
        handler={handleDocument}
        defaultValue={document.title}
        target="title"
      />

      <TextInput
        inputTitle="작성자"
        placeholder="작성자명을 입력해주세요(산모명)"
        handler={handleDocument}
        defaultValue={document.writer}
        target="writer"
      />

      <DateInput
        inputTitle="작성일"
        handler={handleDocument}
        defaultValue={document.created_date}
        target="created_date"
      />


      <FileInput
        inputTitle="썸네일 이미지"
        handler={handleNoneEvent}
        width={360}
        height={250}
        information="가로 360px 세로 250px 이상 권장"
        defaultValue={document.thumb_image}
        target="thumb_image"
      />

      <StarPicker />

      <ToggleButton
        inputTitle="베스트후기 선정"
        handler={handleNoneEvent}
        value={document.is_best}
        target="is_best"
      />

      <Editor
        previewStyle="vertical"
        width="100%"
        height="90vh"
        initialEditType="wysiwyg"
        placeholder="글쓰기"
        ref={editor}
      />
      <button onClick={handleSave} className="upload">
        업로드
      </button>
    </div>
  );
}
