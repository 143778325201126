import React, { useEffect } from "react";
import { uploadFile } from "../lib/api";
import { useFilePicker } from "use-file-picker";
import "./filePicker.css";
const FilePicker = ({ width, height, information, handler, defaultValue, target }) => {

  const [
    openFileSelector,
    { plainFiles },
  ] = useFilePicker({
    multiple: false,
    accept: [".jpg", ".png", ".jpeg"],
  });

  useEffect(() => {
    if (plainFiles.length === 0) return;
    uploadFile(plainFiles[0])
      .then((newUrl) => {
        handler(newUrl, target);
      })

    // eslint-disable-next-line
  }, [plainFiles]);

  return (
    <div
      onClick={() => openFileSelector()}
      className={"imagePicker" + (defaultValue ? " uploaded" : "")}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <span>
        {plainFiles.length === 0 ? (
          <>
            이미지 업로드
            <br />
            {information ? `(${information})` : ""}
          </>
        ) : (
          "이미지 변경"
        )}
      </span>
      {defaultValue ? <img className="previewImg" src={defaultValue}  alt="preview"/> : ""}
    </div>
  );
};

export default FilePicker;
