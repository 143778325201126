import firebase from "firebase/app";
import { useDispatch } from "react-redux";
import { login, logout } from "./modules/auth";

import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

//redux, 전체 세션관리등을 할 예정
const Root = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(login(user));
      } else {
        dispatch(logout());
      }
    });
  }, [dispatch]);
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default Root;
