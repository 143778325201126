import {useRef, useCallback, useState, useEffect} from "react";
import firebase from "firebase/app";
import markdownToTxt from "markdown-to-txt";

import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";

import { images2url, uploadDocument } from "../lib/api";

import SelectInput from "../components/SelectInput";
import TextInput from "../components/TextInput";
import DateInput from "../components/DateInput";
import FileInput from "../components/FileInput";
import ToggleButton from "../components/ToggleButton";
import FullLoading from "../components/FullLoading";

import "./writeDocument.css";
import useDocument from "../custom/useDocument";

const noneImageUrl = "https://firebasestorage.googleapis.com/v0/b/dearcare-test.appspot.com/o/images%2Fnone.jpeg?alt=media&token=516a7039-40a5-4666-b09c-29d2defc8c39"

const editionCategory = {
  _교육: {
    name: "교육",
  },
  _제휴: {
    name: "제휴",
  },
  _이벤트: {
    name: "이벤트",
  },
  _기타: {
    name: "기타",
  },
};

const requireItem = ["is_notice", "created_date", "title", "category"]

const EditionWrite = ({ history }) => {
  const editor = useRef();

  // eslint-disable-next-line no-unused-vars
  const [document, setDocument, handleDocument, handleNoneEvent] = useDocument({
    is_notice: false,
    created_date: "",
    title: "",
    thumb_image: "",
    article_md: "",
    article_str: "",
    category: {
      root: "not",
    },
  });

  const [isUpload, setUpload] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(()=>{
    console.log(document)
  },[document])

  const handleSave = useCallback(async () => {
    for(const idx in requireItem){
      const key = requireItem[idx];

      if (document[key] === null || document[key].toString() === ""){
        console.log(key)
        alert("일부 정보가 누락되었습니다");
        return;
      }
    }

    if (document.is_notice && document.thumb_image === ""){
      alert("상단 노출 게시글은 이미지를 업로드 해주시기 바랍니다");
      return;
    }

    if (document.category.root === "not" ){
      alert("일부 정보가 누락되었습니다");
      return;
    }

    try {
      setUpload(true);

      setMessage("에디터 이미지 업로드 중");
      let md = editor.current.getInstance().getMarkdown();
      md = await images2url(md);
      
      const saveDocument = {
        ...document,
        thumb_image: document.is_notice? document.thumb_image: noneImageUrl, 
        created_date: firebase.firestore.Timestamp.fromDate(new Date(document.created_date)),
        article_md: md,
        article_str: markdownToTxt(md.replace(/<[^>]*>?/gm, '')).replace(/image/g, ""),
        root_category: document.category.root,
      }

      setMessage("전체 문서 업로드 중");
      const result = await uploadDocument(saveDocument, "edition");

      setMessage("업로드 완료! (3초후 페이지 이동)");
      
      setTimeout(() => {
        history.push("/edition/detail?id=" + result.id);
      }, 3000);
    } catch (err) {
      console.log(err)
      alert("업로드 중 얘기치 않은 오류가 발생했습니다");
    }
  }, [document, history]);

  return (
    <div className="writeDocument">
      <FullLoading display={isUpload} msg={message} />
      <h1>새 공지사항 작성</h1>
      <TextInput
        inputTitle="공지사항 제목"
        placeholder="공지사항에 들어가는 제목을 입력해주세요"
        handler={handleDocument}
        defaultValue={document.title}
        target="title"
      />

      <DateInput
        inputTitle="공지사항 작성일"
        handler={handleDocument}
        defaultValue={document.created_date}
        target="created_date"
      />

      <SelectInput
        inputTitle="카테고리"
        category={editionCategory}
        handler={handleNoneEvent}
        exist_child={false}
        value={document.category}
        target="category"
      />

      <FileInput
        inputTitle="썸네일 이미지"
        handler={handleNoneEvent}
        width={360}
        height={179}
        information="가로 360px 세로 180px 권장"
        defaultValue={document.thumb_image}
        target="thumb_image"
      />

      <ToggleButton
        inputTitle="상단노출 설정"
        handler={handleNoneEvent}
        value={document.is_notice}
        target="is_notice"
      />

      <Editor
        previewStyle="vertical"
        width="100%"
        height="90vh"
        initialEditType="wysiwyg"
        placeholder="글쓰기"
        ref={editor}
      />

      <button onClick={handleSave} className="upload">
        업로드
      </button>
    </div>
  );
};

export default EditionWrite;
