import React from "react";
import "./dateInput.css";

const DateInput = ({ inputTitle, handler, defaultValue, target }) => {
  return (
    <div className="dateInput">
      <div className="inputTitle"> - {inputTitle}</div>
      <input
        type="date"
        className="input"
        onChange={(e) => handler(e, target)}
        defaultValue={defaultValue? defaultValue: ""}
      />
    </div>
  );
};

export default DateInput;
