import { firebaseLogin } from "../lib/api";
import "firebase/auth";
import "./login.css";
import React, { useEffect, useState } from "react";
import {useSelector} from 'react-redux';

const Login = ({history}) => {
  const user = useSelector(state => state.auth.user)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
	useEffect(()=>{
		if(user)
			history.push("/apply")

	}, [history, user])

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleEnter = (e)=> {
    if(e.key === "Enter"){
      handleLogin()
    }
  }
  const handleLogin = async () => {
    // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    // 	.then(() => {})

    const result = await firebaseLogin(email, password);
		console.log(result);

    switch (result.code) {
      case "auth/invalid-email":
        setMessage("잘못된 이메일 형식 입니다");
        break;
      case "auth/user-not-found":
      case "auth/wrong-password": 
      setMessage("이메일 혹은 비밀번호가 잘못 되었습니다");
        break
      default:
        setMessage("서버에 문제가 있습니다");
    }
  };


  return (
    <div className="login">
      <div className="icon">
        <img src="/assets/dearcare-logo-big.svg" alt="logo"/>
      </div>
      <div className="inputBox">
        <input type="text" placeholder="이메일" onChange={handleEmail} />
        <input
          type="password"
          placeholder="비밀번호"
          onChange={handlePassword}
          onKeyDown={handleEnter}
        />
      </div>
      <button className="loginButton" onClick={handleLogin}>
        로그인
      </button>
      <span className="notify">
        {message}
      </span>
    </div>
  );
};

export default Login;
