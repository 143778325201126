import servicePriceDict from "./servicePriceDict";

const noneServiceDict = {
  one: 1,
  two: 2,
  three: 3,
  over: 4,
}

const childCountDict = {
  first: "첫쨰",
  second : "둘째",
  third: "셋째 이상",
}

const birthTypeDict = {
  undetermined: "미정",
  natural: "자연분만",
  surgery: "제왕절개",
}

const petDict = {
  one: "1마리",
  two: "2마리",
  over: "3마리 이상"
}

const getDateString = (dateValue) => {
  const {year, month, day} = dateValue
  return `${year}.${month}.${day}`
}

const getServiceString = (serviceValue) => {
  const {baby, income, service} = serviceValue

  return servicePriceDict[baby][income][service].text
}

const getServiceDay = (serviceValue, startDate, serviceTerm) => {
  const {year, month, day} = startDate
  const {baby, income, service} = serviceValue

  const week =  servicePriceDict[baby][income][service][serviceTerm].days / 5
  let date = new Date(`${year}/${month}/${day}`)

  date.setDate(date.getDate() + week * 7 + 1)

  return `${date.getUTCFullYear()}.${date.getUTCMonth()+1}.${date.getUTCDate()}`
}

const getNoneServiceDay = (value, startDate) =>{
  const {year, month, day} = startDate
  const serviceDay = noneServiceDict[value]

  let date = new Date(`${year}/${month}/${day}`)
  if (serviceDay === 4){
    return `${year}.${month}.${day} ~ (4주 이상)`
  } else {
    date.setDate(date.getDate() + serviceDay * 7 + 1)

    return `${year}.${month}.${day} ~ ${date.getUTCFullYear()}.${date.getUTCMonth()+1}.${date.getUTCDate()}`
  }
}

const getPetString = (petType) => {
  const petArray = []

  for(const idx in petType){
    if(petType[idx].value)
      petArray.push(petType[idx].title)
  }

  return petArray.join(", ")
}

export {
  noneServiceDict,
  childCountDict,
  birthTypeDict,
  petDict,
  getDateString,
  getServiceString,
  getServiceDay,
  getPetString,
  getNoneServiceDay,

}