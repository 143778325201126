import {useEffect, useState} from "react";
import queryString from "query-string";
import { getApplyDocument } from "../lib/api";

import {
  birthTypeDict,
  childCountDict,
  getDateString,
  getNoneServiceDay,
  getPetString,
  getServiceDay,
  getServiceString,
  petDict
} from "../lib/utils";

import "./applyDetail.css";


const Domain = ({type, value}) => {
  return (
    <div className="domain">
      <div className="type">{type}</div>
      <div className="value">{value}</div>
    </div>
  )
}

const ApplyDetail = ({ location }) => {
  const [docID, setDocID] = useState("");
  const [result, setResult] = useState(null);

  useEffect(() => {
    const query = queryString.parse(location.search);
    setDocID(query.id);
  }, [location]);

  useEffect(() => {
    if (docID === "") return;
    getApplyDocument(docID)
      .then((nextDocument) => {
        setResult(nextDocument);
      });
  }, [docID]);

  return (
    <div className="applyDetail">
      { result &&
      <div className="article">
        <div className="inner">
          <div className="title">
            예약기록지 정보
          </div>
          <div className="date">
            작성일: {result.timestamp.toDate().toLocaleString()}
          </div>

          <div className="separator"/>
          <Domain type="이름" value={result.mom.name}/>
          <Domain type="연락처" value={result.mom.phone}/>
          <Domain type="이메일" value={result.mom.mail}/>
          <Domain type="주소" value={result.mom.location}/>
          <Domain type="상세주소" value={result.mom.locationDetail === ""? "-": result.mom.locationDetail}/>
          <div className="separator"/>

          <Domain type="출산여부" value={result.mom.pregnant? "출산후":"출산전" }/>
          <Domain type="출산일" value={result.mom.pregnant? getDateString(result.birth.babyBirthDay): '-'}/>
          <Domain type="출산예정일" value={result.mom.pregnant? '-' : getDateString(result.birth.babyBirthDay)}/>
          <Domain type="출산자녀" value={childCountDict[result.birth.childCount]}/>
          <Domain type="분만형태" value={birthTypeDict[result.birth.birthType]}/>
          <Domain type="조리원이용여부" value={result.birth.careCenter? "이용함" : "이용안함"}/>
          <Domain type="조리원이용기간" value={result.birth.careCenterInfo === ""? "-" : result.birth.careCenterInfo}/>
          <Domain type="희망서비스시작일" value={getDateString(result.birth.serviceStartDay)}/>
          <div className="separator"/>

          <Domain type="바우처확인여부" value={result.voucher.voucherSearch? "확인됨" : "확인안됨"}/>
          <Domain type="바우처등급유형" value={result.voucher.voucherSearch? getServiceString(result.voucher.voucherType) : '-'}/>
          <Domain type="희망서비스이용기간" value={ result.voucher.voucherSearch?
            `${getDateString(result.birth.serviceStartDay)} ~ ${getServiceDay(result.voucher.voucherType, result.birth.serviceStartDay, result.voucher.serviceDay)}` :
            getNoneServiceDay(result.voucher.noneVoucherServiceDay, result.birth.serviceStartDay)
          }/>
          <div className="separator"/>

          <Domain type="반려동물" value={getPetString(result.other.petType)? getPetString(result.other.petType): "없음"}/>
          <Domain type="반려동물수" value={getPetString(result.other.petType)? petDict[result.other.petCount]: "-"}/>
          <Domain type="요청사항" value={result.other.otherNeeds ===""? "-" : result.other.otherNeeds }/>
        </div>
      </div>
      }
    </div>
  )
}

export default ApplyDetail;