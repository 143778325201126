import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const splitComma = (str) => {
  return [...str.split(",").map((val) => val.trim())];
};

function createRandomPath(mime) {
  return "images/" + uuidv4() + "." + mime;
}

async function uploadStringImage(base64String) {
  const db = firebase.storage();

  const parseMime = base64String.match(/image\/(png|jpg|jpeg|gif)/g)[0];
  const mime = parseMime.slice(6, parseMime.length);
  const url = createRandomPath(mime);

  const snapshot = await db
    .ref()
    .child(url)
    .putString(base64String, "data_url");
  return await snapshot.ref.getDownloadURL();
}

async function images2url(md) {
  //extract base64 images
  const matchArray = md.match(/data:image\/(png|jpg|jpeg|gif);base64,.*=/g);

  let replaceMd = md;
  for (let idx = 0; matchArray && idx < matchArray.length; idx++) {
    const base64String = matchArray[idx];
    const downloadURL = await uploadStringImage(base64String);

    //base64로 인코딩된 이미지를 업로드된 url 링크로 교체
    replaceMd = replaceMd.replace(base64String, downloadURL);
  }
  return replaceMd;
}

async function uploadFile(file) {
  const db = firebase.storage();

  const mime = file.type.slice(6, file.type.length);
  const url = createRandomPath(mime);

  const snapshot = await db.ref().child(url).put(file);
  return await snapshot.ref.getDownloadURL();
}

async function uploadDocument(document, target) {
  const db = firebase.firestore();

  return await db.collection(target).add({
    ...document,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  });
}


async function updateDocument(document, id, target) {
  const db = firebase.firestore();

  return await db.collection(target).doc(id).update({
    ...document,
  });
}

async function getDocumentList(target, sort_type) {
  const db = firebase.firestore();

  const snapshot = await db
    .collection(target)
    .orderBy(sort_type, "desc")
    .limit(10)
    .get();

  const lastVisible = await snapshot.docs[snapshot.docs.length - 1];
  return { snapshot, lastVisible };
}

async function getNextDocumentList(target, sort_type, lastVisible) {
  const db = firebase.firestore();

  const snapshot = await db
    .collection(target)
    .orderBy(sort_type, "desc")
    .startAfter(lastVisible)
    .limit(5)
    .get();

  const nextLastVisible = await snapshot.docs[snapshot.docs.length - 1];
  return { snapshot, nextLastVisible };
}

async function getSingleDocument(target, id) {
  const db = firebase.firestore();
  const snapshot = await db.collection(target).doc(id).get();

  return snapshot.data();
}

async function getApplyDocument(id) {
  const db = firebase.firestore();
  const snapshot = await db.collection("apply").doc(id).get();
  const result = snapshot.data();

  if (!result.checked){
    await db.collection("apply").doc(id).update({
      checked: true
    })
  }

  return result
}

async function deleteDocument(target, id) {
  const db = firebase.firestore();
  return await db.collection(target).doc(id).delete();
}

async function firebaseLogin(email, password) {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  const auth = firebase.auth();
  try {
    return await auth.signInWithEmailAndPassword(email, password);
  } catch (e) {
    // console.error(e);
    return e
  }
}

async function firebaseLogout() {
  const auth = firebase.auth();
  try {
    return await auth.signOut();
  } catch (e) {
    console.error(e);
  }
}

function getAuth() {
  return firebase.auth().currentUser;
}

// async function initAuth() {
//
// }

export {
  uploadStringImage,
  images2url,
  uploadFile,
  uploadDocument,
  updateDocument,
  deleteDocument,
  getNextDocumentList,
  // uploadByteImage,
  getApplyDocument,
  getDocumentList,
  getSingleDocument,
  firebaseLogin,
  firebaseLogout,
  getAuth,
  splitComma
};
