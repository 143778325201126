import { Link } from "react-router-dom";

import useDocumentList from "../custom/useDocumentList";
import "./event.css"

const Event = () => {
  const [article] = useDocumentList("event", "timestamp")

  return (
    <div className="event">
      <h1>전체 이벤트 리스트</h1>
      <div className="eventContainer">
        <Link className="createEvent" to="/event/write">
          새 이벤트 작성
        </Link>
        {article.map((val, idx) => (
          <Link to={"/event/detail?id=" + val.id} className="item" key={val.id}>
            <div className="left">
              <div className="title">
                {`${val.is_result? "[이벤트 결과] ": ""}${val.title}`}
              </div>
              <div className="content">
								<div className="term">
									<p>이벤트 기간</p>
									<div className="start">{val.start_date.toDate().toLocaleDateString()}</div> ~ <div className="end">{val.end_date.toDate().toLocaleDateString()}</div>
								</div>
							</div>
            </div>
            <div className="right">
              <img src={val.thumb_image} alt="review" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Event;
