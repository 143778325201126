import React, {useState} from "react";

import { deleteDocument } from "../lib/api";
import FullLoading from "../components/FullLoading";

import "./removeButton.css";

const RemoveButton = ({ target, id, history }) => {
  const [isDelete, setDelete] = useState(false);
  const [message, setMessage] = useState("");

  const handleDelete = async () => {
    const finalCheck = window.confirm("해당 게시글을 삭제하시겠습니까?");
    if (finalCheck) {
      setDelete(true);
      setMessage("게시글 삭제중...");

      const result = await deleteDocument(target, id);
      console.log(result);

      setMessage("삭제 완료! (3초후 페이지 이동)");
      setTimeout(() => {
        history.push(`/${target}`);
      }, 3000);
    }
  };

  return (
    <div className="removeButton" onClick={handleDelete}>
      <FullLoading display={isDelete} msg={message} />
      게시글 삭제
    </div>
  );
};

export default RemoveButton;
