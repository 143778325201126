import React from "react";
import "./selectInput.css";

const SelectInput = ({ category, inputTitle, handler, exist_child, value, target }) => {
  const handleCategory =  (e, category_type) => {
    if (category_type === "root"){
      handler({
        root: e.target.value,
        child: "not",
      }, target)
    }
    else {
      handler({
        ...value,
        child: e.target.value,
      }, target)
    }
  };

  return (
    <div className="selcetInput">
      <div className="inputTitle"> - {inputTitle}</div>
      <select
        className="root input"
        value={value.root}
        onChange={(e)=>handleCategory(e, "root")}
      >
        <option value="not" disabled>
          메인 카테고리 선택
        </option>
        {[...Object.keys(category)].map((val, idx) => (
          <option value={val} key={idx}>
            {category[val].name}
          </option>
        ))}
      </select>
      {exist_child && value.root !== "not" &&
        <select
          className="child input"
          value={value.child}
          onChange={(e)=>handleCategory(e, "child")}
        >
          <option value="not" disabled >
            서브 카테고리 선택
          </option>
          {value.root &&
            category[value.root].child.map((val, idx) => (
              <option value={val} key={idx}>
                {val}
              </option>
            ))}
        </select>
      }
    </div>
  );
};

export default SelectInput;
