import React from "react";
import "./toggleButton.css";


const ToggleButton = ({ inputTitle, handler, value, target }) => {
  const handleValue = (value) => {
    handler(value, target);
  };

  return (
    <div className="toggleInput">
      <div className="inputTitle"> - {inputTitle}</div>
      <div className="toggleButton">
        <div
          className={((value === false) ? "selected" : "")}
          onClick={() => handleValue(false)}
        >
          아니요
        </div>
        <div
          className={((value === true) ? "selected" : "")}
          onClick={() => handleValue(true)}
        >
          예
        </div>
      </div>
    </div>
  );
};

export default ToggleButton;
