import {useEffect, useState} from "react";
import {getDocumentList, getNextDocumentList} from "../lib/api";

const useDocumentList = (target, sort_type) => {
  const [article, setArticle] = useState([]);
  const [last, setLast] = useState(null);

  useEffect(() => {
    getDocumentList(target, sort_type)
      .then(({snapshot, lastVisible}) => {
        const newArticle = [];
        
        snapshot.forEach((doc) => {
          newArticle.push({ ...doc.data(), id: doc.id });
        });
        setLast(lastVisible);
        setArticle(newArticle);
      })
  }, [target, sort_type])

  useEffect(() => {
    const infiniteScroll = () => {
      const { documentElement, body } = document;

      const scrollHeight = Math.max(documentElement.scrollHeight, body.scrollHeight);
      const scrollTop = Math.max(documentElement.scrollTop, body.scrollTop);
      const clientHeight = documentElement.clientHeight;

      if (scrollTop + clientHeight >= (scrollHeight - 100)) {
        if (last && article.length !== 0) {
          getNextDocumentList(target, sort_type, last)
            .then(({snapshot, nextLastVisible}) => {
              const newArticle = [];

              snapshot.forEach((doc) => {
                newArticle.push({ ...doc.data(), id: doc.id });
              });

              setLast(nextLastVisible)
              setArticle(article.concat(newArticle))
            });
        }
      }
    };

    window.addEventListener("scroll", infiniteScroll, true);
    return () => window.removeEventListener("scroll", infiniteScroll, true);
  }, [last, article, target, sort_type]);

  return [article]
}

export default useDocumentList;