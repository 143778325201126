import React from "react";
import { Link } from "react-router-dom";
import "./review.css";
import useDocumentList from "../custom/useDocumentList";

const Review = () => {
  const [article] = useDocumentList("review", "created_date")

  return (
    <div className="review">
      <h1>전체 후기 리스트</h1>
      <div className="reviewContainer">
        <Link className="createReview" to="/review/write">
          새 후기 작성
        </Link>
        {article.map((val, idx) => (
          <Link to={"/review/detail?id=" + val.id} className="item" key={val.id}>
            <div className="left">
              <div className="title">{`${val.is_best? "[베스트] ": ""}${val.title}`}</div>
              <div className="content">{val.article_str}</div>
              <div className="bottom">
                <div className="stars">
                  {[...Array(5).keys()].map((_, idx) => {
                    const star = val.star - 1;
                    if (idx * 2 > star)
                      return ""
                    else if ((star + 1) % 2 === 1 && (idx * 2 >= star))
                      return <div key={idx} className="half"/>
                    else
                      return <div key={idx} />
                  })}
                </div>
                <div className="date">
                  {val.created_date.toDate().toLocaleDateString()}
                </div>
              </div>

            </div>
            <div className="right">
              <img src={val.thumb_image} alt="review" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Review;
