import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import Header from "./components/Header";

import Login from "./routes/Login";

import Apply from "./routes/Apply";
import ApplyDetail from "./routes/ApplyDetail";

import Review from "./routes/Review";
import ReviewWrite from "./routes/ReviewWrite";
import ReviewDetail from "./routes/ReviewDetail";
import ReviewEdit from "./routes/ReviewEdit";

import Event from "./routes/Event";
import EventWrite from "./routes/EventWrite";
import EventDetail from "./routes/EventDetail";
import EventEdit from "./routes/EventEdit";

import Toon from "./routes/Toon";
import ToonWrite from "./routes/ToonWrite";
import ToonDetail from "./routes/ToonDetail";
import ToonEdit from "./routes/ToonEdit";

import News from "./routes/News";
import NewsWrite from "./routes/NewsWrite";
import NewsDetail from "./routes/NewsDetail";
import NewsEdit from "./routes/NewsEdit";

import Edition from "./routes/Edition";
import EditionWrite from "./routes/EditionWrite";
import EditionDetail from "./routes/EditionDetail";
import EditionEdit from "./routes/EditionEdit";

import "./app.css";

export default function App() {
  const user = useSelector((state) => state.auth.user);
  // console.log(user);

  return (
    <div className="app">
      {user && <Header />}
      <div className="main">
        <Switch>
          <Route path="/" component={Login} exact />
          {user && (
            <>
              <Route path="/apply" component={Apply} exact />
              <Route path="/apply/detail" component={ApplyDetail} exact />
              
              <Route path="/review" component={Review} exact />
              <Route path="/review/write" component={ReviewWrite} exact />
              <Route path="/review/detail" component={ReviewDetail} exact />
              <Route path="/review/edit" component={ReviewEdit} exact />
              
              <Route path="/event" component={Event} exact />
              <Route path="/event/write" component={EventWrite} exact />
              <Route path="/event/detail" component={EventDetail} exact />
              <Route path="/event/edit" component={EventEdit} exact />
              
              <Route path="/news" component={News} exact />
              <Route path="/news/write" component={NewsWrite} exact />
              <Route path="/news/detail" component={NewsDetail} exact />
              <Route path="/news/edit" component={NewsEdit} exact />
              
              <Route path="/toon" component={Toon} exact />
              <Route path="/toon/write" component={ToonWrite} exact />
              <Route path="/toon/detail" component={ToonDetail} exact />
              <Route path="/toon/edit" component={ToonEdit} exact />

              <Route path="/edition/" component={Edition} exact/>
              <Route path="/edition/write" component={EditionWrite} exact/>
              <Route path="/edition/detail" component={EditionDetail} exact/>
              <Route path="/edition/edit" component={EditionEdit} exact/>
            </>
          )}
          <Redirect path="*" to="/" />
        </Switch>
      </div>
    </div>
  );
}
