import React from "react";

import FilePicker from "./FilePicker";
import "./fileInput.css";

const FileInput = ({ inputTitle, width, height, information, handler, defaultValue, target }) => {
  return (
    <div className="fileInput">
      <div className="inputTitle"> - {inputTitle}</div>
      <FilePicker width={width} height={height} information={information} handler={handler} defaultValue={defaultValue} target={target}/>
    </div>
  );
  // return FileInput;
};

export default FileInput;
