import { useEffect } from "react"
import { Link } from "react-router-dom";

import {getDateString, getNoneServiceDay, getServiceDay, getServiceString} from "../lib/utils";
import useDocumentList from "../custom/useDocumentList";

import "./apply.css";

const Apply = () => {
  const [article] = useDocumentList("apply", "timestamp")

  useEffect(()=>{
    console.log(article)
  }, [article])

  return(
    <div className="apply">
      <h1>전체 신청현황 리스트</h1>
      <div className="applyContainer">
        {
          article.map((val, idx)=>
          <Link to={"/apply/detail?id=" + val.id} className={`item${!val.checked? " selected": ""}`} key={val.id}>
            <div className="title">
              {val.mom.location}
            </div>
            <div className="serviceInfo">
              <div className="voucher">
                {val.voucher.voucherSearch?getServiceString(val.voucher.voucherType): "바우처 조회 X"}
              </div>
              <div className="date">
                {
                  val.voucher.voucherSearch?
                    `${getDateString(val.birth.serviceStartDay)} ~ ${getServiceDay(val.voucher.voucherType, val.birth.serviceStartDay, val.voucher.serviceDay)}` :
                    getNoneServiceDay(val.voucher.noneVoucherServiceDay, val.birth.serviceStartDay)
                }
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  )
}

export default Apply