import {createAction, handleActions} from 'redux-actions'

const LOGIN = "auth/LOGIN";
const LOGOUT = "auth/LOGOUT";

export const login = createAction(LOGIN, input => input);
export const logout = createAction(LOGOUT);

const initialState = {
	user : null
}

const auth = handleActions({
	[LOGIN]: (state, action) => ({
		user: action.payload
	}),
	[LOGOUT]: (state, action) => ({
		user: null
	}),
}, initialState)

export default auth;