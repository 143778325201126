import React, {useEffect, useState} from "react";
import { firebaseLogout } from "../lib/api";
import { Link, useLocation } from "react-router-dom";
import "./header.css";

const Header = () => {
	const location = useLocation();
  const [hide, setHide] = useState(false);


  useEffect(() => {
    const hideList = ["/"];
    if (hideList.includes(location.pathname)) setHide(true);
    else setHide(false);

    window.scrollTo(0, 0);
    
  }, [location]);

	const category = [
    {
      name: "신청현황",
      route: "/apply"
    },
		{
			name: "후기",
			route: "/review"
		},
		{
			name: "이벤트",
			route: "/event"
		},
		{
			name: "공지사항",
			route: "/edition"
		},
		{
			name: "디어뉴스",
			route: "/news"
		},
		{
			name: "디어툰",
			route: "/toon"
		}
	]

	const handleLogout = () => {
		firebaseLogout();
	}

  return (
    <nav className="header"
			style={{
				display : hide? "none": "flex"
			}}
		>
      <div className="content">
        <Link to="/review" className="left side">
          <img src="/assets/dearcare-logo-w.svg" alt="" />
        </Link>
				<div className="center">
				{
					category.map((val, idx) => 
					<Link to={val.route} className="route" key={idx}>
						{val.name}
					</Link>
				)}
				</div>
        <div className="right side" onClick={handleLogout}>로그아웃</div>
      </div>
    </nav>
  );
};

export default Header;
