import { Link } from "react-router-dom";

import useDocumentList from "../custom/useDocumentList";
import "./event.css"

const editionCategory = {
  _교육: {
    name: "교육",
  },
  _제휴: {
    name: "제휴",
  },
  _이벤트: {
    name: "이벤트",
  },
  _기타: {
    name: "기타",
  },
};

const Edition = () => {
  const [article] = useDocumentList("edition", "created_date")

  return (
    <div className="event">
      <h1>전체 공지사항 리스트</h1>
      <div className="eventContainer">
        <Link className="createEvent" to="/edition/write">
          새 공지사항 작성
        </Link>
        {article.map((val, idx) => (
          <Link to={"/edition/detail?id=" + val.id} className="item" key={val.id}>
            <div className="left">
              <div className="title">
                {`${val.title}`}
                <div className="tag">
                  {`${editionCategory[val.root_category].name} (상단노출 ${val.is_notice? "O" : "X"})`} 
                </div>
              </div>
              <div className="content">
								<div className="term">
									<p>작성일</p>
									<div className="start">
                    {val.created_date.toDate().toLocaleDateString()}
                  </div>
								</div>
							</div>
            </div>
            <div className="right">
              <img src={val.thumb_image} alt="review" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Edition;
